import React, { useState, useContext, useCallback, useEffect } from "react"
import {
  Header,
  Footer,
  Frame,
  Intro,
  AccessoryCardSelectable,
  DCXButton,
  ShowPrice,
  CheckoutHeader,
} from "../../components"
import { checkoutAccessoriesImage } from "../../assets"
import { getMaxStringLengthByField } from "../../utils"
import { CarContext } from "../../context"
import {
  getSelectedModel,
  getModelAccessories,
  urlWithSession,
  removeSaleFromUrl,
} from "../../utils"
import { navigate, graphql } from "gatsby"
import {
  useSelectedAccessoriesTotalPrice,
  useSurchargedPrices,
  useIncludeTaxes,
} from "../../hooks"
import { CalculatePriceFull, infoToSessionString } from "../../utils"
import { Payment, Model, Accessory, AppContextType } from "../../types"

type AccessoriesPageType = {
  data: {
    allModels: {
      nodes: Model[]
    }
    allAccessories: {
      nodes: Accessory[]
    }
  }
}

const CheckoutAccessoriesPage: React.FC<AccessoriesPageType> = ({ data }) => {
  const appContext = useContext<AppContextType>(CarContext)
  const {
    appLoading,
    selectedVersion,
    setCheckoutData,
    checkoutData,
    saveAccessories,
    offers,
    selectedOffer,
    payment,
    terms,
    color: colorId,
  } = appContext
  const [selectedModel] = useState(
    getSelectedModel(data.allModels.nodes, selectedVersion)
  )
  const allAccessories = data.allAccessories.nodes

  // Check context to determine if sale is present
  const offer = offers?.find(of => of.internalId === selectedOffer)

  const maxNameLength = getMaxStringLengthByField(
    checkoutData.selectedAccessories,
    "name"
  )

  // Get selected accessories total prices and all accessories elements for being displayed
  const [
    accessoriesTotalPrice,
    ,
    allModelAccessories,
  ] = useSelectedAccessoriesTotalPrice()

  const [prices] = useSurchargedPrices({
    prices: offer
      ? {
          priceList: offer.priceList,
          priceCash: offer.priceCash,
          priceCredit: offer.priceCredit,
        }
      : selectedVersion,
    colorId,
  })

  const [includeTaxes] = useIncludeTaxes()
  const PaymentInfo = CalculatePriceFull({
    appContext,
    offer,
    prices,
    includeTaxes: includeTaxes,
    aditionals: [accessoriesTotalPrice],
  })

  // Redirect to home if no version nor offer was detected
  useEffect(() => {
    if (appLoading) return
    if (!selectedVersion && !offer) {
      navigate(removeSaleFromUrl(urlWithSession("/home")))
    }
  }, [appLoading, selectedVersion, offer])

  // Rebuild and populate fresh accessories if the previous exists and belongs to different model
  useEffect(() => {
    const modelId = selectedModel?.ID
    if (modelId && modelId !== checkoutData.selectedAccessoriesModelId) {
      saveAccessories({
        selectedAccessoriesModelId: modelId,
        selectedAccessories: getModelAccessories(
          selectedModel,
          allAccessories
        ).map(acc => ({ ...acc, selected: false })),
      })
      infoToSessionString({
        insuranceSimulationId: null,
      })
      setCheckoutData(prev => ({
        ...prev,
        insurance: null,
      }))
    }
  }, [
    checkoutData.selectedAccessoriesModelId,
    selectedModel,
    allAccessories,
    saveAccessories,
  ])

  const handleClick = useCallback(
    accessoryId => checkKey => e => {
      if (
        !checkKey ||
        [13, 32].includes(e.keyCode) ||
        [" ", "Enter"].includes(e.key)
      ) {
        e.preventDefault()
        // Update selected state for this accessory
        saveAccessories({
          accessoryId,
        })
      }
    },
    [saveAccessories]
  )

  const getAccessoryPrice = useCallback(
    accessoryPrice => {
      if (appContext.payment === Payment.cash)
        return {
          cashPrice: accessoryPrice,
        }
      const priceWithoutAcc = CalculatePriceFull({
        appContext,
        prices,
      })
      const priceWithAcc = CalculatePriceFull({
        appContext,
        prices,
        aditionals: [accessoryPrice],
      })
      const monthlyPrice =
        priceWithAcc.monthlyPrice - priceWithoutAcc.monthlyPrice

      return {
        monthlyPrice,
        // firstPaymentPrice: 0,
        cashPrice: accessoryPrice,
      }
    },
    [appContext, prices]
  )

  const handleClickBack = useCallback(() => {
    navigate(urlWithSession(`/config/${selectedModel.slug}`), {
      replace: true,
    })
  }, [selectedModel])

  const handleClickNext = useCallback(() => {
    navigate(urlWithSession("/checkout/tax"))
  }, [])

  return (
    <>
      <Header />
      <CheckoutHeader />
      <div className="checkout-accessories-page standard-page">
        <div className="content">
          <Frame
            className="mobile"
            image={checkoutAccessoriesImage}
            title="¿Quieres agregar accesorios para tu nuevo auto?"
            desc="Agrega los que quieras"
          />
          <div className="page-title">Agrega tus accesorios</div>
          <Intro
            className="desktop"
            image={checkoutAccessoriesImage}
            title="¿Quieres agregar accesorios para tu nuevo auto?"
            subtitle="Agrega los que quieras"
          />
          <div className="accessories-section">
            {allModelAccessories.map((acc, idx) => (
              <AccessoryCardSelectable
                key={idx}
                data={acc}
                selected={acc.selected}
                onClick={handleClick(acc.code)}
                nameRows={maxNameLength >= 13 ? "row-2" : ""}
                {...getAccessoryPrice(acc.price)}
              />
            ))}
          </div>
          <div className="page-title">
            {payment === Payment.cash ? "Total a pagar" : "Cuota total"}
          </div>
          <ShowPrice
            appContext={appContext}
            PaymentInfo={PaymentInfo}
            priceUnavailableText=""
            data={{
              priceList: selectedVersion?.priceList,
              terms,
              slug: selectedModel?.slug,
            }}
            center
            big
            discount={false}
            chips={false}
            fullClickeable={false}
            from={false}
          />
        </div>
        <div className="buttons">
          <DCXButton type="secondary" text="ATRÁS" onClick={handleClickBack} />
          <DCXButton
            type="primary"
            text="SIGUIENTE"
            onClick={handleClickNext}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query ALL_ACCESSORIES_CHECKOUT {
    allModels {
      nodes {
        ...ModelBasic
        versions {
          ...VersionBasic
        }
        ...ConfigOptions
      }
    }
    allAccessories {
      nodes {
        ...AccessoriesBasic
      }
    }
  }
`

export default CheckoutAccessoriesPage
